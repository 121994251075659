import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/mswsn/projects/vcs-childhood-elearning-q2-2020/src/components/layout.tsx";
import { Navigation } from "../components/navigation";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Barnens vardag`}</h1>
    <p>{`Olika typer av våld och sexuella övergrepp är nära sammankopplade. Barn som utsatts för eller bevittnat våld löper ökad risk att utsättas igen. I Norden utsätts så mycket som ett av fem barn för sexuella övergrepp under sin barndom.`}</p>
    <p>{`Men det går att förebygga och mildra konsekvenserna av sexuella övergrepp mot barn. Childhood är övertygade om att det är lättare att bygga starka barn än att laga trasiga vuxna.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABXRK5U5RSJ//EABkQAQEAAwEAAAAAAAAAAAAAAAECAxESI//aAAgBAQABBQLEwEK3WN60Tj58itH/xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8ByEv/xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8BrX//xAAcEAEAAQQDAAAAAAAAAAAAAAAAEQIiMVFhgZH/2gAIAQEABj8Cv8cMKNw6Q//EABsQAAICAwEAAAAAAAAAAAAAAAERADEhQWFR/9oACAEBAAE/IUIkAARtMADs/I4O2YFE9IiCrFbEDU45/9oADAMBAAIAAwAAABCr/wD/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EHGkKP/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8Q1sjeF//EABwQAQEAAwADAQAAAAAAAAAAAAERACExQVGBwf/aAAgBAQABPxCKhobRDf3NMQaPB+mJVAkb6PnCWu9ZelQzq0JLr0XDSo2qli0z/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Familj",
          "title": "Familj",
          "src": "/static/1699b6b885ca63af4d7b8cce58c2343c/e5166/family-1.jpg",
          "srcSet": ["/static/1699b6b885ca63af4d7b8cce58c2343c/f93b5/family-1.jpg 300w", "/static/1699b6b885ca63af4d7b8cce58c2343c/b4294/family-1.jpg 600w", "/static/1699b6b885ca63af4d7b8cce58c2343c/e5166/family-1.jpg 1200w", "/static/1699b6b885ca63af4d7b8cce58c2343c/d9c39/family-1.jpg 1800w", "/static/1699b6b885ca63af4d7b8cce58c2343c/df51d/family-1.jpg 2400w", "/static/1699b6b885ca63af4d7b8cce58c2343c/8c3d9/family-1.jpg 3797w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Vad innebär sexuella övergrepp?`}</h3>
    <p>{`Sexuella övergrepp mot barn är ett samlingsnamn för alla former av sexuella handlingar som ett barn utsätts för av en annan person. Det inkluderar både fysiska övergrepp och icke-fysiska övergrepp.`}</p>
    <p>{`Så här skriver Rädda Barnen i "Detta borde alla veta om sexuella övergrepp":`}</p>
    <p>{`Sexuella övergrepp mot barn innefattar alla former av sexuella handlingar som ett barn utsätts för av en annan person. Sexuella övergrepp kännetecknas av att utnyttja ett barns beroendeställning eller utsätta barnet för handlingar som det inte kan förstå eller är moget för. Med barn menas personer under 15 år alternativt en person mellan 15 och 18 år som på något sätt står i en beroendeställning till gärningspersonen. Sexuella övergrepp mot barn kan vara både fysiska och icke fysiska. Fysiska övergrepp kan till exempel vara beröring av barnets privata kroppsdelar, att barnet tvingas ta på en annan persons könsorgan eller olika former av samlag. Icke-fysiska övergrepp kan vara att barnet tvingas till att titta på ett könsorgan eller visa sin egen kropp, att tala till barnet på ett sexuellt utstuderat sätt, att smygtitta på barnet när det badar eller klär av sig, att tvinga barnet att titta på pornografiska filmer, bilder eller böcker, att exploatera barnet genom att dokumentera de sexuella övergreppen barnet utsätts för eller prostitution.`}</p>
    <Navigation previous={{
      chapter: "Kapitel 1",
      title: "Childhood och vårt samarbete",
      link: "kapitel-1"
    }} next={{
      chapter: "Kapitel 3",
      title: "Hur blir jag en viktig vuxen?",
      link: "kapitel-3"
    }} mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      